<template>
  <b-card>

    <!-- form -->
    <validation-observer ref="manufacturerCreate">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Name"
              label-class="label-class"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required|max:255"
              >
                <b-form-input
                  v-model="form.name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Name"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- submit button -->
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import { required, max } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      form: {
        name: '',
      },
      required,
      max,
    }
  },
  methods: {
    ...mapActions('hardwareManufacturers', [
      'createItem',
    ]),
    async validationForm() {
      const valid = await this.$refs.manufacturerCreate.validate()

      if (valid) {
        try {
          await this.createItem({
            ...this.form,
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'hardware-manufacturer-list' })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.manufacturerCreate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>
